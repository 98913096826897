import React, { PropsWithChildren, ReactElement } from 'react';
import { TextStyle } from '../theme';

import { Container, Level, Message } from './styledComponents';

export { Level } from './styledComponents';

export interface Props extends PropsWithChildren<{}> {
  level: Level;
  textSize?: TextStyle;
}

const Feedback = ({
  level,
  children,
  textSize = TextStyle.BODY_3,
}: Props): ReactElement => (
  <Container level={level}>
    <Message styleKey={textSize} level={level}>
      {children}
    </Message>
  </Container>
);

export default Feedback;
