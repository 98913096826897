import { View } from 'react-native';
import styled, { css, FlattenSimpleInterpolation } from 'styled-components';

import Text from '../Text';
import { Color, ThemeProps } from '../theme';

export enum Level {
  ERROR,
  SUCCESS,
}

interface LevelProps extends ThemeProps {
  level: Level;
}

const colorsByLevel = {
  [Level.SUCCESS]: {
    background: Color.SUCCESS_LIGHT,
    text: Color.SUCCESS,
  },
  [Level.ERROR]: {
    background: Color.DANGER_LIGHT,
    text: Color.DANGER,
  },
};

export const Container = styled(View)<LevelProps>`
  ${({ theme, level }: LevelProps): FlattenSimpleInterpolation => css`
    background-color: ${theme.color[colorsByLevel[level].background]};
    border-radius: ${theme.radius.small};
    padding: ${theme.spacing.medium};
  `}
`;

export const Message = styled(Text)<LevelProps>`
  color: ${({ theme, level }: LevelProps): string =>
    theme.color[colorsByLevel[level].text]};
`;
